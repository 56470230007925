

.logincard {
    width: 30%;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1520px) {
    .logincard {
        width: 30%;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 1024px) {
    .logincard {
        width: 90%;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
    }
}
