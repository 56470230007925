.navbar,
.pc {
    display: flex;
    position: relative;
    flex-flow: row wrap;
    align-items: center;
    align-content: center;
    height: 4rem;
    padding: 0 12.5rem;
}

.nav-logo {
    margin-right: 4rem;
    /* flex: 0 1 16.8%; */
    /* min-width: 9rem */
}

.navbar-area {
    align-items: center;
    height: 4rem;
    display: flex;
}

.nav-wrapper {
    /* flex: 0 1 32%; */
    margin-right: 4rem;
    height: 100%;
    flex-flow: row nowrap;
    column-gap: 2rem;
}

.nav-boxw {
    display: flex;
  
    /* flex: 1 1 33.33%; */
}

.nav-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    flex-grow: 1;
    text-align: center;
}

.nav-link {
    text-decoration: none;
    font-size: 0.9rem;
    cursor: pointer;
}

.nav-link a {
    white-space: pre-wrap;
    display: inline-flex;
    justify-content: center;
}

.nav-selected {
    position: absolute;
    bottom: 0;
    border-radius: 56px 56px 0 0;
    -webkit-border-radius: 56px 56px 0 0;
    -moz-border-radius: 56px 56px 0 0;
    -ms-border-radius: 56px 56px 0 0;
    -o-border-radius: 56px 56px 0 0;
    width: 3rem;
    height: 4px;

}

.nav-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    /* flex: 0 1 29.80%; */
    gap: 1.25rem;
    flex: auto;
}

.user-help {
    cursor: pointer;
    font-size: 0.9rem;
    justify-content: end;

    flex: auto;
    flex-flow: row nowrap;
}


.nmob {
    /* display: none; */
    padding: 1.5rem 1rem;
    flex-direction: column;
}

.flex-grow {
    justify-content: center;
}

.user-lang {
    padding: 8px 12px;
    line-height: 24px;
}

.user-lang div {
    display: flex;
}

.user-login {
    padding: 8px 12px;
    line-height: 24px;
    flex-grow: 1;
    white-space: nowrap;
}

.user-theme {
    padding: 10px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}

.user-theme-box {
    height: 40px;
    cursor: pointer;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}

.nmobs {
    margin-top: 1.25rem;
    line-height: 2rem;
    flex-flow: row nowrap;
}

.nmobs-menu {
    justify-content: flex-end;
    display: flex;
}

@media (max-width: 1520px) {
    .navbar {
        padding: 0 2rem;
    }

    .nav-user {
        gap: .75rem;
    }
}

@media (max-width: 1024px) {
    .navbar {
        padding: 0 1rem;
    }

    .mlogo {
        margin-bottom: .5rem;
    }

    /* .footer {
        display: none;
    }

    .pc {
        display: none;
    } */

    .nmob {
        display: flex;
    }
}

/* @media (max-width: 468px) {
    .user-row{
        width: 100% !important;
    }
} */