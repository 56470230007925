

.registercard {
    width: 30%;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1520px) {
    .registercard {
        width: 30%;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 1024px) {
    .registercard {
        width: 90%;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
    }
}
