.expand_row {
    /* background: #F5F9FF; */
}

.WorkerTable-status {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap-reverse;
    gap: .625rem;
}

.PcWorkerTable {
    display: block;
}

.MobileTable {
    display: none;
}

.searchb {
    border-radius: 8px 0 0 8px;
    -webkit-border-radius: 8px 0 0 8px;
    -moz-border-radius: 8px 0 0 8px;
    -ms-border-radius: 8px 0 0 8px;
    -o-border-radius: 8px 0 0 8px;
    flex: 0 1 40%;
}
.searchb .ant-btn-primary {
    box-shadow: none;
}

.searchb .ant-input-group >input{
    border: none;
    box-shadow: none;
    line-height: 1.5rem;
    color: inherit;
}
.searchb .ant-input-group{
    color: inherit;
}


.WorkerTable-operate {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 10px;
    flex:auto;
    justify-content: flex-end;
}

.group span{
    display: flex;
}

@media (max-width:568px) {
    .WorkerTable-status {
        flex-flow: row wrap-reverse;
    }

    .WorkerTable-operate {
        margin-bottom: .75rem;
    }

    .PcWorkerTable {
        display: none;
    }

    .MobileTable {
        display: block;
    }
    .searchb{
        flex: 100%;
    }
    .downGroup {
        flex: 100%;
    }
}