.top-view {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}


.top-doc {
    margin: 0 -15rem;
    background-size: 100% 100% !important;
    /* width: 100vw; */
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.tml .ant-timeline-item-last {
    padding: 0;
}

.example-input {
    border: none;
    padding: 0 .5rem;
    display: flex;
    align-items: center;
    font-size: .88rem;
    box-shadow: none;
    font-weight: 400;
}

.example-input:focus {
    box-shadow: 0 0 0 1px #6c6c6c;
}



@media (max-width: 1520px) {
    .top-doc {
        margin: 0 -9.5rem;
    }
}

@media (max-width: 1024px) {
    .top-doc {
        margin: 0 -1rem;
    }
}

@media (max-width: 528px) {
    .top-view {
        grid-template-columns: repeat(2, 1fr);
        gap: .75rem;
    }
}