.footer {
    flex-direction: column;
    display: flex;
    /* height: 18.125rem; */
    background-color: #071629;
}

.footer-nav-box {
    padding: 3rem 19.125rem ;
    justify-content: center;
    column-gap: 3.5rem;
}

.footer-nav-box a:hover {
    color: rgb(238, 238, 238);
}

.footer-nav-box span {
    color: white;
    font-weight: bold;
    font-size: 1rem;
}

.footer-nav-col {
    display: flex;
    flex-direction: column;
}

.footer-nav-col a {
    color: rgb(204, 204, 204);
    text-decoration: underline;
    font-size: 1rem;
    line-height: 1.375rem;
    margin-top: 0.75rem;

}

.footer-icon {
    display: flex;
    margin-top: 1.875rem;
    flex-direction: row;
    width: 100%;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}

.footer-copyright {
    color: rgb(204, 204, 204);
    padding: 0 19.125rem;
    justify-content: space-between;
    flex-wrap: wrap;

}

.footer-copyright .ant-col {
    white-space: nowrap;
}

.footer-copyright a {
    color: rgb(204, 204, 204);
    text-decoration: underline;
}

.footer-mob {
    padding: 0 1rem;
}

.footer-mob,
.ant-divider {
    margin-top: 1rem !important;
}

@media (max-width: 1520px) {
    .footer-nav-box {
        padding: 3rem 5rem;
    }

    .footer-copyright {
        padding: 3rem 5rem;
    }

    .footer {
        height: auto;
    }
}

@media (max-width: 1200px) {

    .footer-mob {
        display: flex;
        flex-direction: column;
    }

    .footer-icon {
        width: auto;
        margin-top: 1.875rem;
        display: flex;
        flex-basis: 9rem;
        justify-content: space-around;
  
    }

    .footer-icon img {
        padding: .125rem;
        height: 1.5rem;
        margin: 0;
    }

    .footer-copyright {
        padding: 0;
        place-content: center;
    }

    .footer-nav-col span {
        color: white;
        font-weight: bold;
        font-size: 1rem;
    }
}