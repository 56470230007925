.ellipsis {
	position: relative;
	display: inline-block;
	word-break: normal;
	word-wrap: normal;
	overflow: hidden;
	line-height: 1;
	width: 1em !important;
	text-indent: 2em;
}

.ellipsis-sp {
	width: 100%;
	display: flex;
	justify-content: center;
	/* flex-wrap: nowrap; */
	color: inherit !important;
}


.ellipsis:after {
	position: absolute;
	display: block;
	bottom: 4px;
	content: "";
	left: 50%;
	transform: translateX(-50%);
	width: 2px;
	height: 2px;
	border-radius: 1px;
	background-color: currentColor;
	box-shadow: -0.3em 0 currentColor, 0 0 currentColor, 0.3em 0 currentColor;
}

.PC {
	display: block;
}

.Mobile {
	display: none;
}

/* Antd Table组件样式控制 */
/*
	tableL 亮色样式控制
	tableD 暗色样式控制
**/

/* 控制表格行内背景颜色交替 */
.tableL .ant-table-thead>tr>:not(.ant-table-cell-scrollbar) {
	background-color: #F5F7F9;
}

.tableL .ant-table-tbody .ant-table-row:nth-child(even) {
	background-color: #F5F9FF;
}

.tableL .ant-table-tbody .ant-table-row:nth-child(odd) {
	background-color: #FFFFFF;
}

.tableD .ant-table-thead>tr>:not(.ant-table-cell-scrollbar) {
	background-color: #090A0A;
}

.tableD .ant-table-tbody .ant-table-row:nth-child(even) {
	background-color: #161B22;
}

.tableD .ant-table-tbody .ant-table-row:nth-child(odd) {
	background-color: #17181A;
}

.tableD .ant-table-tbody>tr.ant-table-row:hover>td {
	background-color: #292c2f;
}

/* 控制表格边距样式 */
.home-table .ant-card-body {
	padding: 2.5rem;
}

@media (max-width: 528px) {
	.home-table .ant-card-body {
		padding: 1rem;
	}

	.home-table .ant-table-row .ant-table-cell {
		padding: 6px 8px;
	}

	.home-table .ant-table-thead .ant-table-cell {
		padding: 6px 8px;
	}

	.home-table .ant-table-cell .ellipsis-sp {
		white-space: nowrap;
	}
}

/* 控制表格点击选择框选中后的背景颜色 */
.tableD .ant-table-row-selected>td {
	background: #2f2f2f !important;
}

/* 选择框的边框及背景色 */
.ckl .ant-checkbox-inner {
	border: 1px solid #e4e4e4;
	background-color: #f1f1f120;
}

.ckd .ant-checkbox-inner {
	border: 1px solid #4b4b4b;
	background-color: #2e2e2e50;
}

/* 选择框选中后的样式 */
.ckl .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #1677ff !important;
	border: 1px solid #1677ff !important;
}

.ckd .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #1677ff !important;
	border: 1px solid #1677ff !important;
}

/* 选择框禁用后的样式 */
.ckl .ant-checkbox-disabled .ant-checkbox-inner {
	background-color: #e5e5e5;
	border: 1px solid transparent;
}

.ckd .ant-checkbox-disabled .ant-checkbox-inner {
	background-color: #2c2c2c;
	border: 1px solid transparent;
}


.carousel {
	align-items: baseline;
	gap: .75rem;
}
.carousel .styles-module_item-container__a8zaY{
	width: 100% !important;
}
@media (max-width: 576px) {
	.PC {
		display: none;
	}

	.Mobile {
		display: block
	}
}