/* 登录、注册、找回密码 */
.lrf{ 
    padding: 2.5rem 3.5rem 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 29.375rem;
}




@media (max-width: 1520px) {
    .lrf {
        padding: 1.75rem 2.5rem 2rem;
        width: 24rem;
    }
}

@media (max-width: 1024px) {
    .lrf {
        padding: 1.75rem 2.5rem 2rem;
        width: 19rem;
    }
}

@media (max-width: 528px) {
    .lrf {
        padding: 1.25rem 2rem 2rem;
        width: 15rem;
    }
}