body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



:root {
  --board-color: #ffffff;
  --border-color: white;
  --fontColor_2: #ffffff;
  --lineColor: #ffffff;
  --shadowColor: #f2eefa
}

.main-box {
  margin: 0 15rem;
  min-height: calc(100vh - 184px);
}

.ant-dropdown {
  border: 1px solid var(--border-color);
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;
}

/* 修改滚动条样式 */

.scrollD ::-webkit-scrollbar,
.scrollL ::-webkit-scrollbar {
  width: 10px;
  /* 滚动条宽度 */
  height: 10px;
  /* 滚动条高度 */
}

/* 修改滚动条拖动块样式 */
.scrollD ::-webkit-scrollbar-thumb {
  background-color: #5d5c5c;
  /* 拖动块颜色 */
  border-radius: 5px;
  /* 拖动块圆角 */
}

/* 修改滚动条背景样式 */
.scrollD ::-webkit-scrollbar-track {
  background-color: #454343;
  /* 背景颜色 */
}

/* 修改滚动条拖动块样式 */
.scrollL ::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* 拖动块颜色 */
  border-radius: 5px;
  /* 拖动块圆角 */
}

/* 修改滚动条背景样式 */
.scrollL ::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* 背景颜色 */
}

/* 全局弹出框样式 */
.ant-modal {
  width: 768px !important;
}

.ant-modal-header {
  background-color: var(--board-color) !important;
}

.ant-modal-title {
  background-color: var(--board-color) !important;
  color: var(--fontColor_2) !important;
}

.ant-modal-body {
  color: var(--fontColor_2) !important;
}

.ant-dropdown-menu {
  background-color: var(--board-color) !important;

}

.ant-modal-content {
  background-color: var(--board-color) !important;
}

.ant-modal-close {
  color: var(--fontColor_2) !important;
}

.ant-select-selection-item {
  color: var(--fontColor_2) !important;
}

.ant-select {
  color: var(--fontColor_2) !important;

  &::placeholder {
    color: var(--fontColor_2) !important;
  }
}

.ant-checkbox {
  color: var(--fontColor_2) !important;
}

.ant-checkbox-group-item {
  color: var(--fontColor_2) !important;
}

/* .ant-dropdown-arrow{
  &::before{
    background-color: var(--board-color) !important;
  }
} */
.ant-input {
  background: var(--board-color) !important;
  color: var(--fontColor_2) !important;
  border: 1px solid;
  /* &::placeholder {
    color: var(--fontColor_2) !important;
  } */
}

.ant-modal-confirm-title {
  color: var(--fontColor_2) !important;
}

.ant-modal-confirm-content {
  color: var(--fontColor_2) !important;
}

.ant-input-clear-icon {
  color: var(--fontColor_2) !important;
}

.ant-input-suffix {
  color: var(--fontColor_2) !important;
  /* .ant-input-password-icon {
    color: var(--fontColor_2) !important;
  } */
}

.ant-timeline-item-tail {
  border-inline-start: 2px solid var(--lineColor) !important;
}

.ant-select-selection-item-remove {
  color: var(--fontColor_2) !important;
}

.ant-dropdown-menu > .ant-dropdown-menu-item > .ant-dropdown-menu-title-content > .downGroup {
  width: 100%;
}

.ant-btn-primary {
  /* box-shadow: 0 2px 0 #233c61; */
  box-shadow: 0 2px 0 var(--shadowColor);
}

.ant-modal-footer > .ant-btn-default {
  background-color: var(--fontColor_2);
}

@media (max-width: 1520px) {
  .main-box {
    margin: 0 9.5rem;
  }

  .top-doc {
    margin: 0 -9.5rem;
  }

  .ant-modal {
    width: 576px !important;
  }
}

@media (max-width: 1024px) {
  .main-box {
    margin: 0 1rem;
  }

  .top-doc {
    margin: 0 -1rem;
  }

  .ant-modal {
    width: 428px !important;
  }
}

@media (max-width:576px) {
  .ant-card-body {
    padding: 12px 8px !important;
  }

  .main-box {
    margin: 0 .5rem;
  }

  .top-doc {
    margin: 0 -0.5rem !important;
  }

  .ant-modal {
    width: 85% !important;
  }
}

.small_modal {
  width: 33rem !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-text-fill-color: var(--fontColor_2) !important;
  background-color: transparent !important;
  transition: background-color 5000s ease-in-out 0s;
  /* 延迟过渡效果 */
  -webkit-transition: background-color 5000s ease-in-out 0s;
  -moz-transition: background-color 5000s ease-in-out 0s;
  -ms-transition: background-color 5000s ease-in-out 0s;
  -o-transition: background-color 5000s ease-in-out 0s;
}