.pc-card-view,
.mob-card-view {
    width: 100%;
    display: grid;
    gap: 1.5rem;
    align-items: center;
}

.card-view-item {
    display: flex;
    align-items: center;
    width: 100%;
}

.view-item-row {
    flex-flow: row nowrap;
    row-gap: 1rem;
}

.view-item-text {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1.5rem;
    align-items: center;
    height: 100px;
}

.mob-card-view {
    display: none;
    border: none;
}

.echarts canvas{
    z-index: unset !important;
}

.image {
    min-height: 3rem;
    min-width: 3rem;
}

@media (max-width:768px) {
    .pc-card-view {
        display: none;
    }

    .view-item-text {
        height: 100%;
    }

    .mob-card-view {
        gap: .625rem;
        display: grid;
    }

    .view-item-row {
        flex-flow: row wrap;
    }
}

@media (max-width: 576px) {

    .inctab .ant-table-row .ant-table-cell {
        padding: 6px 8px;
    }

    .inctab .ant-table-thead .ant-table-cell {
        padding: 6px 8px;
    }

    .view-item-text {
        display: flex;
        justify-content: center;
    }

}